import { Overrides } from '@core/theme/types/main';

export const initializeOutlinedInputOverrides = (
  config?: Overrides['MuiOutlinedInput']
): Overrides['MuiOutlinedInput'] => {
  return {
    root: {
      fontSize: 14,
      height: 36,
      borderRadius: 6,
    },
    input: {},
    multiline: {
      height: 'auto',
    },
    inputMarginDense: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    ...config,
  };
};
