import { createContext, FC, useContext, useMemo } from 'react';

import { useOptions } from '@core/contexts/OptionsContext';
import { t } from '@core/i18n';
import { FilterPeriodType } from '@shared/components/filter-period';
import { SwitchType } from '@shared/components/switch';
import { AvailableFilters, FiltersTypeConfigs, FilterType } from '@shared/types/common/filter';
import {
  availableLettingTypeOptionsToSelectOptions,
  groupOptionsByBuildingType,
  idArrayToOptions,
  objectToOptions,
  sourceOptionsSelectOptions,
} from '@shared/utils/options';

import { useConfig } from './ConfigContext';

export type OptionsItem<T = Id> = { id: T; label: string };

export type UseFieldsResult = {
  [key in AvailableFilters]: FiltersTypeConfigs;
};

export const FieldsContext = createContext<UseFieldsResult>({} as UseFieldsResult);

export const useFields = () => {
  return useContext(FieldsContext);
};

export const FieldsProvider: FC = ({ children }) => {
  // OPTIONS
  const { coogan, constants, user } = useConfig();
  const {
    availableSizeMetricOptions,
    companyTypeOptions,
    devPipelineFundingOptions,
    devPipelineStatusOptions,
    devPipelineTypeOptions,
    disposalFittedSpaceOptions,
    disposalPublishedToOptions,
    disposalStatusOptions,
    disposalTermsOfEngagementOptions,
    fitoutConceptOptions,
    fitoutConditionOptions,
    filterableRentMetricOptions,
    includeInstructionOptions,
    investmentGradeOptions,
    investmentOptions,
    jointExclusiveInstructionOptions,
    kycCompletedOptions,
    sectorOptions,
    tagOptions,
    teamOptions,
    tenancyStatusOptions,
    tenureOptions,
  } = useOptions();

  const currencySymbol: string = useMemo(() => constants.localisation.currency.symbol.trim(), [constants]);

  const contactTypesOptions = useMemo(() => {
    return objectToOptions(constants?.contactTypes);
  }, [constants]);

  const contactMarketingListOptions = useMemo(() => {
    return idArrayToOptions(constants.marketingLists);
  }, [constants]);

  const leaseTypeOptions = useMemo(() => {
    return availableLettingTypeOptionsToSelectOptions(constants?.availableLettingTypeOptions);
  }, [constants]);

  const propertyTypeOptions = useMemo(() => groupOptionsByBuildingType(constants, user), [constants, user]);

  const salesTypesOptions = useMemo(() => {
    return objectToOptions(constants?.availableSaleTypeOptions);
  }, [constants]);

  const sourceOptions = useMemo(() => sourceOptionsSelectOptions(constants?.localisation?.sourceOptions), [constants]);

  const transactionStatusOptions = useMemo(() => {
    return objectToOptions(constants?.transactionStatusOptions);
  }, [constants]);

  const result: UseFieldsResult = {
    [AvailableFilters.buildingTypes]: {
      type: FilterType.multiGroupSelect,
      label: t('property_types'),
      groupOptions: propertyTypeOptions,
    },

    [AvailableFilters.capVal]: {
      type: FilterType.range,
      label: t('capital_value_bracket_per_sqft_bracket'),
    },

    [AvailableFilters.company]: {
      type: FilterType.lookupCompany,
      label: t('company'),
      placeholder: t('xtextx_ellipsis', { text: t('search_companies') }),
    },

    [AvailableFilters.companyType]: {
      type: FilterType.multiSelect,
      label: t('type'),
      options: companyTypeOptions,
    },

    [AvailableFilters.completionDate]: {
      type: FilterType.dateRange,
      label: t('completion_date'),
    },

    [AvailableFilters.contact]: {
      type: FilterType.lookupContact,
      label: t('contact'),
      placeholder: t('xtextx_ellipsis', { text: t('search_contacts') }),
    },

    [AvailableFilters.contactType]: {
      type: FilterType.multiSelect,
      label: t('type'),
      options: contactTypesOptions,
    },

    [AvailableFilters.created]: {
      type: FilterType.date,
      label: t('date_added_1'),
    },

    [AvailableFilters.createdCrmCompanies]: {
      type: FilterType.date,
      label: t('date_added_1'),
      supportedTypes: [FilterPeriodType.range, FilterPeriodType.daysAgo],
    },

    [AvailableFilters.createdCrmContacts]: {
      type: FilterType.date,
      label: t('date_added_1'),
      supportedTypes: [FilterPeriodType.range, FilterPeriodType.daysAgo],
    },

    [AvailableFilters.createdDateRange]: {
      type: FilterType.dateRange,
      label: t('date_added'),
    },

    [AvailableFilters.createdTransactionsSales]: {
      type: FilterType.date,
      label: t('date_added_1'),
      supportedTypes: [FilterPeriodType.range, FilterPeriodType.daysAgo],
    },

    [AvailableFilters.disposalDevelopmentCompletionYear]: {
      type: FilterType.range,
      label: t('completion_year'),
    },

    [AvailableFilters.disposalDevelopmentFloorSize]: {
      type: FilterType.range,
      label: t('floor_size_1'),
      defaultMetric: constants.defaultSizeMetric,
      metric: true,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.disposalDevelopmentFunding]: {
      type: FilterType.multiSelect,
      label: t('funding'),
      options: devPipelineFundingOptions,
    },

    [AvailableFilters.disposalDevelopmentNoOfFloors]: {
      type: FilterType.range,
      label: t('no_of_floors_1'),
    },

    [AvailableFilters.disposalDevelopmentPreLetCommitted]: {
      type: FilterType.range,
      label: t('pre_hyphen_let_or_committed_size'),
      defaultMetric: constants.defaultSizeMetric,
      metric: true,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.disposalDevelopmentSpeculative]: {
      type: FilterType.range,
      label: t('speculative_size'),
      defaultMetric: constants.defaultSizeMetric,
      metric: true,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.disposalDevelopmentStatus]: {
      type: FilterType.multiSelect,
      label: t('development_status_1'),
      options: devPipelineStatusOptions,
    },

    [AvailableFilters.disposalDevelopmentTotalAreaProposed]: {
      type: FilterType.range,
      label: t('total_area_proposed_size'),
      defaultMetric: constants.defaultSizeMetric,
      metric: true,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.disposalDevelopmentType]: {
      type: FilterType.multiSelect,
      label: t('development_type'),
      options: devPipelineTypeOptions,
    },

    [AvailableFilters.disposalFittedSpaces]: {
      type: FilterType.multiSelect,
      label: t('fitted_space_1'),
      options: disposalFittedSpaceOptions,
    },

    [AvailableFilters.disposalHasPresentation]: {
      type: FilterType.singleSelect,
      label: t('interest_schedule_presentations'),
      options: [
        { label: t('select'), id: '' },
        { label: t('has_presentation'), id: '1' },
        { label: t('no_presentation'), id: '0' },
      ],
    },

    [AvailableFilters.disposalStatus]: {
      type: FilterType.multiSelect,
      removable: false,
      label: t('status'),
      options: disposalStatusOptions,
    },

    [AvailableFilters.disposalPublishedTo]: {
      type: FilterType.multiSelect,
      label: t('published_to'),
      options: disposalPublishedToOptions,
    },

    [AvailableFilters.fitoutConcept]: {
      type: FilterType.multiSelect,
      label: t('fitout_concept'),
      options: fitoutConceptOptions,
    },

    [AvailableFilters.fitoutCondition]: {
      type: FilterType.multiSelect,
      label: t('fitout_condition'),
      options: fitoutConditionOptions,
    },

    [AvailableFilters.has_active_disposals]: {
      type: FilterType.switch,
      switchType: SwitchType.Square,
      label: t('has_active_disposals'),
    },

    [AvailableFilters.has_active_leases]: {
      type: FilterType.switch,
      switchType: SwitchType.Square,
      label: t('has_active_leases'),
    },

    [AvailableFilters.has_active_requirements]: {
      type: FilterType.switch,
      switchType: SwitchType.Square,
      label: t('has_active_requirements'),
    },

    [AvailableFilters.has_active_sales]: {
      type: FilterType.switch,
      switchType: SwitchType.Square,
      label: t('has_active_sales'),
    },

    [AvailableFilters.has_active_viewings]: {
      type: FilterType.switch,
      switchType: SwitchType.Square,
      label: t('has_active_viewings'),
    },

    [AvailableFilters.investment]: {
      type: FilterType.multiSelect,
      label: t('investment'),
      options: investmentOptions,
    },

    [AvailableFilters.investmentGrade]: {
      type: FilterType.multiSelect,
      label: t('investment_question'),
      labelCheckbox: true,
      options: [...investmentGradeOptions, { id: 'no_preference', label: t('no_preference') }],
    },

    [AvailableFilters.jointExclusiveInstructions]: {
      type: FilterType.multiSelect,
      label: t('joint_or_exclusive_instruction'),
      options: jointExclusiveInstructionOptions,
    },

    [AvailableFilters.kycCompleted]: {
      type: FilterType.multiSelect,
      label: t('kyc_checks_undertaken_question'),
      options: kycCompletedOptions,
      visible: !!user.organisation?.settings?.kyc_enabled,
    },

    [AvailableFilters.location]: {
      type: FilterType.text,
      label: t('location'),
      placeholder: t('enter_location'),
    },

    [AvailableFilters.leaseBreak]: {
      type: FilterType.date,
      label: t('lease_break'),
    },

    [AvailableFilters.leaseEnd]: {
      type: FilterType.date,
      label: t('lease_end_date'),
    },

    [AvailableFilters.leaseExpiry]: {
      type: FilterType.date,
      label: t('lease_expiry'),
    },

    [AvailableFilters.leaseStart]: {
      type: FilterType.date,
      label: t('lease_start_date'),
    },

    [AvailableFilters.leaseTypes]: {
      type: FilterType.multiSelect,
      label: t('lease_type_1'),
      options: leaseTypeOptions,
    },

    [AvailableFilters.leaseReminder]: {
      type: FilterType.dateRange,
      label: t('lease_end_reminder_dates'),
    },

    [AvailableFilters.newMatches]: {
      type: FilterType.switch,
      switchType: SwitchType.Square,
      label: t('only_show_new_match_requests'),
    },

    [AvailableFilters.marketing_lists]: {
      type: FilterType.multiSelect,
      label: t('marketing_lists'),
      options: contactMarketingListOptions,
    },

    [AvailableFilters.marketing_permission]: {
      type: FilterType.singleSelect,
      label: t('property_alerts'),
      options: [
        { label: t('select'), id: '' },
        { label: t('yes'), id: '1' },
        { label: t('no'), id: '0' },
      ],
    },

    [AvailableFilters.niy]: {
      type: FilterType.range,
      label: t('net_initial_yield'),
    },

    [AvailableFilters.ourInstructions]: {
      type: FilterType.multiSelect,
      label: t('our_instructions_question'),
      options: includeInstructionOptions,
    },

    [AvailableFilters.pendingMatches]: {
      type: FilterType.switch,
      switchType: SwitchType.Square,
      label: t('only_show_pending_match_requests'),
    },

    [AvailableFilters.salePrice]: {
      type: FilterType.range,
      label: `${t('sale_price')} (${currencySymbol})`,
    },

    [AvailableFilters.sectors]: {
      type: FilterType.multiSelect,
      label: t('sectors'),
      options: sectorOptions,
    },

    [AvailableFilters.postcode]: {
      type: FilterType.text,
      label: t('postcode'),
      placeholder: t('enter_postcode'),
    },

    [AvailableFilters.publishedToRadius]: {
      type: FilterType.multiSelect,
      label: t('published_to_radius'),
      options: [
        { label: t('published'), id: 'published' },
        { label: t('not_published'), id: 'not_published' },
        { label: t('valid'), id: 'valid' },
        { label: t('not_valid'), id: 'not_valid' },
      ],
    },

    [AvailableFilters.rent]: {
      type: FilterType.range,
      label: `${t('rent_range')} (${currencySymbol})`,
      defaultMetric: constants.defaultRentMetric,
      metric: true,
      metricOptions: filterableRentMetricOptions,
    },

    [AvailableFilters.rentReview]: {
      type: FilterType.date,
      label: t('rent_review'),
    },

    [AvailableFilters.reverseSegmentIntersectLogic]: {
      type: FilterType.switch,
      switchType: SwitchType.Square,
      label: t('reverse_location_filter_bracket_impersonate_only_bracket'),
      condition: coogan,
    },

    [AvailableFilters.saleTypes]: {
      type: FilterType.multiSelect,
      label: t('sale_type'),
      options: salesTypesOptions,
    },

    [AvailableFilters.signatureDate]: {
      type: FilterType.date,
      label: t('signature_date'),
    },

    [AvailableFilters.size]: {
      type: FilterType.range,
      label: t('size_range'),
      defaultMetric: constants.defaultSizeMetric,
      metric: true,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.sources]: {
      type: FilterType.multiSelect,
      label: t('source'),
      options: sourceOptions,
    },

    [AvailableFilters.tags]: {
      type: FilterType.multiSelect,
      label: t('tags'),
      options: tagOptions,
    },

    [AvailableFilters.teams]: {
      type: FilterType.multiSelect,
      label: t('teams'),
      options: teamOptions,
    },

    [AvailableFilters.tenancyStatus]: {
      type: FilterType.multiSelect,
      label: t('tenancy_status_1'),
      options: tenancyStatusOptions,
    },

    [AvailableFilters.tenure]: {
      type: FilterType.multiSelect,
      label: t('tenure'),
      options: tenureOptions,
    },

    [AvailableFilters.disposalTermsOfEngagement]: {
      type: FilterType.multiSelect,
      label: t('have_terms_of_engagement_been_agreed_question'),
      options: disposalTermsOfEngagementOptions,
    },

    [AvailableFilters.tny]: {
      type: FilterType.range,
      label: t('target_net_yield'),
    },

    [AvailableFilters.transactionStatus]: {
      type: FilterType.multiSelect,
      label: t('status'),
      options: transactionStatusOptions,
    },

    [AvailableFilters.updated]: {
      type: FilterType.date,
      label: t('date_updated'),
      supportedTypes: [FilterPeriodType.range, FilterPeriodType.daysAgo],
    },

    [AvailableFilters.user]: {
      type: FilterType.lookupUser,
      label: t('user'),
      placeholder: t('xtextx_ellipsis', { text: t('search_users') }),
    },

    [AvailableFilters.waultToExpiry]: {
      type: FilterType.range,
      label: t('wault_to_expiry'),
    },
  };

  return <FieldsContext.Provider value={result}>{children}</FieldsContext.Provider>;
};
