import { createContext, FC, useContext, useMemo } from 'react';

import { TaskFrequency } from '@apis/tasksApi.types';
import { useConfig } from '@core/contexts/ConfigContext';
import { t } from '@core/i18n';
import { ShortWeekDay } from '@modules/insights/pages/viewings-old/components/viewings-drawer-old/components/viewings-task-edit/ViewingsTaskEdit.types';
import { ViewingOutcome } from '@shared/models/viewing/viewings';
import {
  filterOptions,
  objectToOptions,
  requirementStatusOptionsToOptions,
  societyRegionsToOptions,
  sourceOptionsSelectOptions,
  teamsListToSelectOptions,
} from '@shared/utils/options';

export type OptionsItem<T = Id> = { id: T; label: string };

export type UseOptionResult = {
  acquisitionIntelStatusOptions: OptionsItem[];
  archiveRadioOptions: OptionsItem[];
  availableMeasureOptions: OptionsItem[];
  availableSizeMetricOptions: OptionsItem[];
  availableTaskTypeOptions: OptionsItem[];
  booleanOptions: OptionsItem[];
  buildingTypeOptions: OptionsItem[];
  companyTypeOptions: OptionsItem[];
  confirmedEventOptions: OptionsItem[];
  consentSourceOptions: OptionsItem[];
  contactTypeOptions: OptionsItem[];
  contactTitleOptions: OptionsItem[];
  countryOptions: OptionsItem<string>[];
  creatableTaskTypeOptions: OptionsItem[];
  devPipelineFundingOptions: OptionsItem[];
  devPipelineStatusOptions: OptionsItem[];
  devPipelineTypeOptions: OptionsItem[];
  diaryTaskStatusOptions: OptionsItem[];
  discountReasonOptions: OptionsItem[];
  disposalConditionTypeOptions: OptionsItem[];
  disposalFittedSpaceOptions: OptionsItem[];
  disposalPublishedToOptions: OptionsItem[];
  disposalStatusOptions: OptionsItem[];
  disposalTermsOfEngagementOptions: OptionsItem[];
  epcBandOptions: OptionsItem[];
  eventNotificationOptions: OptionsItem[];
  fitoutConceptOptions: OptionsItem[];
  fitoutConditionOptions: OptionsItem[];
  filterableRentMetricOptions: OptionsItem[];
  includeInstructionOptions: OptionsItem[];
  investmentGradeOptions: OptionsItem[];
  investmentOptions: OptionsItem[];
  jointExclusiveInstructionOptions: OptionsItem[];
  kycCompletedOptions: OptionsItem[];
  leaseExpiryReasonOptions: OptionsItem[];
  leaseTypeOptions: OptionsItem[];
  marketingListOptions: OptionsItem[];
  metricOptions: OptionsItem[];
  openEventOptions: OptionsItem[];
  quarterOptions: OptionsItem<string>[];
  rentMetricOptions: OptionsItem[];
  rentTypeOptions: OptionsItem[];
  requirementListFilterOptions: OptionsItem[];
  requirementStatusOptions: OptionsItem[];
  requirementTypeOptions: OptionsItem[];
  sectorOptions: OptionsItem[];
  showEventOptions: OptionsItem[];
  societyRegionOptions: OptionsItem[];
  sourceOptions: OptionsItem[];
  tagOptions: OptionsItem[];
  taskRepeatOptions: OptionsItem<TaskFrequency>[];
  teamOptions: OptionsItem[];
  teamStatusOptions: OptionsItem[];
  tenancyStatusOptions: OptionsItem[];
  tenureOptions: OptionsItem[];
  viewingFeedbackRadioOptions: OptionsItem<ViewingOutcome>[];
  weekDayOptions: OptionsItem<ShortWeekDay>[];
};

export const OptionsContext = createContext<UseOptionResult>({} as UseOptionResult);

export const useOptions = () => {
  return useContext(OptionsContext);
};

export const OptionsProvider: FC = ({ children }) => {
  const { constants, teams, user } = useConfig();

  const allSectorOptions = useMemo(() => objectToOptions(constants.sectors), [constants.sectors]);
  const allTaskTypeOptions = useMemo(() => objectToOptions(constants.taskTypes), [constants.taskTypes]);

  const availableSectorOptions = useMemo(
    () => filterOptions(allSectorOptions, user.organisation?.settings.sectors_visible, true),
    [allSectorOptions, user.organisation?.settings.sectors_visible]
  );
  const availableTaskTypeOptions = useMemo(
    () => filterOptions(allTaskTypeOptions, user.organisation?.settings.task_types_visible, true),
    [allTaskTypeOptions, user.organisation?.settings.task_types_visible]
  );

  const result: UseOptionResult = {
    acquisitionIntelStatusOptions: useMemo(
      () => requirementStatusOptionsToOptions(constants?.acquisitionIntelStatusOptions),
      []
    ),
    archiveRadioOptions: useMemo(
      () => [
        { id: 1, label: t('archive') },
        { id: 0, label: t('dont_archive') },
      ],
      []
    ),
    availableMeasureOptions: useMemo(() => objectToOptions(constants.availableMeasures), [constants]),
    availableSizeMetricOptions: useMemo(() => objectToOptions(constants.availableSizeMetrics), [constants]),
    availableTaskTypeOptions: availableTaskTypeOptions,
    booleanOptions: useMemo(
      () => [
        { id: 1, label: t('yes') },
        { id: 0, label: t('no') },
      ],
      []
    ),
    buildingTypeOptions: useMemo(() => objectToOptions(constants?.buildingTypes), [constants]),
    companyTypeOptions: useMemo(() => objectToOptions(constants.companyTypes), [constants]),
    confirmedEventOptions: useMemo(
      () => [
        { id: '0', label: t('provisional_1') },
        { id: '1', label: t('confirmed') },
      ],
      []
    ),
    consentSourceOptions: useMemo(
      () => [
        { id: 1, label: t('website') },
        { id: 2, label: t('verbal') },
        { id: 3, label: t('email') },
      ],
      []
    ),
    contactTitleOptions: useMemo(
      () => [
        { id: 1, label: t('mr') },
        { id: 2, label: t('mrs') },
        { id: 3, label: t('ms') },
        { id: 4, label: t('miss') },
        { id: 5, label: t('dr') },
        { id: 6, label: t('professor') },
        { id: 7, label: t('reverend') },
        { id: 8, label: t('pastor') },
        { id: 9, label: t('rabbi') },
        { id: 10, label: t('sister') },
      ],
      []
    ),
    contactTypeOptions: useMemo(() => objectToOptions(constants.contactTypes), [constants]),
    countryOptions: useMemo(() => objectToOptions(constants.countryOptions, true), [constants.countryOptions]),
    creatableTaskTypeOptions: useMemo(
      () => filterOptions(availableTaskTypeOptions, constants.creatableTaskTypes, true),
      [availableTaskTypeOptions, constants.creatableTaskTypes]
    ),
    devPipelineFundingOptions: useMemo(() => objectToOptions(constants.devPipelineFundingOptions), [constants]),
    devPipelineStatusOptions: useMemo(() => objectToOptions(constants.devPipelineStatusOptions), [constants]),
    devPipelineTypeOptions: useMemo(() => objectToOptions(constants.devPipelineTypeOptions), [constants]),
    diaryTaskStatusOptions: useMemo(
      () => [
        { label: t('all_statuses'), id: '' },
        { label: t('open'), id: 'open' },
        { label: t('completed'), id: 'completed' },
      ],
      []
    ),
    disposalFittedSpaceOptions: useMemo(() => objectToOptions(constants.disposalFittedSpaceOptions), [constants]),
    disposalPublishedToOptions: useMemo(() => objectToOptions(constants.disposalsPublishedToOptions), [constants]),
    disposalStatusOptions: useMemo(
      () => [
        { label: t('coming_soon'), id: '1' },
        { label: t('available'), id: '2' },
        { label: t('under_offer'), id: '3' },
        { label: t('let'), id: '4' },
        { label: t('sold'), id: '7' },
        { label: t('withdrawn'), id: '5' },
        { label: t('disinstructed'), id: '6' },
      ],
      []
    ),
    disposalTermsOfEngagementOptions: useMemo(
      () => objectToOptions(constants.disposalTermsOfEngagementOptions),
      [constants]
    ),
    discountReasonOptions: useMemo(() => objectToOptions(constants.matchDiscountReasonOptions), [constants]),
    disposalConditionTypeOptions: useMemo(() => objectToOptions(constants?.disposalConditionTypes), [constants]),
    epcBandOptions: useMemo(() => objectToOptions(constants.epcBandOptions), [constants]),
    eventNotificationOptions: useMemo(
      () => [
        { id: '0', label: 'No' },
        { id: '1', label: 'Yes' },
      ],
      []
    ),
    fitoutConceptOptions: useMemo(() => objectToOptions(constants.fitoutConceptOptions), [constants]),
    fitoutConditionOptions: useMemo(() => objectToOptions(constants.fitoutConditionOptions), [constants]),
    filterableRentMetricOptions: useMemo(() => objectToOptions(constants.rentMetricsFilterable), [constants]),
    includeInstructionOptions: useMemo(
      () => [
        { id: '1', label: t('include_our_instructions') },
        { id: '0', label: t('include_market_intel') },
      ],
      []
    ),
    investmentGradeOptions: useMemo(() => objectToOptions(constants.investmentGradeOptions), [constants]),
    investmentOptions: useMemo(
      () => [
        { label: t('investment'), id: '1' },
        { label: t('occupational'), id: '0' },
      ],
      []
    ),
    jointExclusiveInstructionOptions: useMemo(
      () => [
        { id: 'exclusive_instructions', label: t('exclusive_instructions') },
        { id: 'joint_instructions', label: t('joint_instructions') },
      ],
      []
    ),
    kycCompletedOptions: useMemo(() => objectToOptions(constants.kycCompletedOptions), [constants]),
    leaseExpiryReasonOptions: useMemo(() => objectToOptions(constants.leaseExpiryReasonOptions), [constants]),
    leaseTypeOptions: useMemo(() => objectToOptions(constants.leaseTypes), [constants]),
    marketingListOptions: useMemo(
      () => constants.marketingLists.map((option) => ({ id: option.id, label: option.name })),
      [constants]
    ),
    metricOptions: useMemo(() => objectToOptions(constants.genericMetrics), [constants]),
    openEventOptions: useMemo(
      () => [
        { id: '1', label: 'Yes' },
        { id: '0', label: 'No, Invite only' },
      ],
      []
    ),
    quarterOptions: useMemo(
      () => [
        { id: 'Q1', label: 'Q1' },
        { id: 'Q2', label: 'Q2' },
        { id: 'Q3', label: 'Q3' },
        { id: 'Q4', label: 'Q4' },
      ],
      []
    ),
    rentMetricOptions: useMemo(() => objectToOptions(constants.rentMetrics), [constants]),
    rentTypeOptions: useMemo(() => objectToOptions(constants.rentTypes), [constants]),
    requirementListFilterOptions: useMemo(() => {
      return (constants?.requirementListOptions || [])
        .filter((option) => option.can_filter)
        .map((option) => ({
          id: option.id,
          label: option.name,
        }));
    }, [constants]),
    requirementStatusOptions: useMemo(
      () => requirementStatusOptionsToOptions(constants.requirementStatusOptions),
      [constants]
    ),
    requirementTypeOptions: useMemo(
      () => [
        { id: 'direct_enquiries', label: t('direct_enquiries') },
        { id: 'agent_led', label: t('agentled') },
        { id: 'our_acquisitions', label: t('our_acquisitions') },
        { id: 'availability_schedules', label: t('availability_schedules') },
      ],
      []
    ),
    sectorOptions: availableSectorOptions,
    showEventOptions: useMemo(
      () => [
        { label: t('my_events'), id: 'mine' },
        { label: t('team_events'), id: 'teams' },
        { label: t('all_events'), id: '' },
      ],
      []
    ),
    societyRegionOptions: useMemo(
      () => societyRegionsToOptions(constants?.societyRegions),
      [constants?.societyRegions]
    ),
    sourceOptions: useMemo(() => sourceOptionsSelectOptions(constants.localisation.sourceOptions), [constants]),
    tagOptions: useMemo(() => objectToOptions(constants.tags), [constants]),
    taskRepeatOptions: useMemo(
      () => [
        { label: t('doesnt_repeat'), id: TaskFrequency.None },
        { label: t('daily'), id: TaskFrequency.Daily },
        { label: t('weekly'), id: TaskFrequency.Weekly },
        { label: t('monthly'), id: TaskFrequency.Monthly },
        { label: t('annually'), id: TaskFrequency.Yearly },
      ],
      []
    ),
    teamOptions: useMemo(() => teamsListToSelectOptions(teams), [teams]),
    teamStatusOptions: useMemo(
      () => [
        { label: t('active'), id: '0' },
        { label: t('scraper'), id: '1' },
        { label: t('scraper_with_meta'), id: '2' },
        { label: t('inactive'), id: '3' },
        { label: t('suspended'), id: '10' },
        { label: t('deleted'), id: '100' },
      ],
      []
    ),
    tenancyStatusOptions: useMemo(() => objectToOptions(constants.targetTenancyDetails), [constants]),
    tenureOptions: useMemo(
      () => [
        { id: 'to_let', label: t('to_let') },
        { id: 'for_sale', label: t('for_sale') },
      ],
      []
    ),
    viewingFeedbackRadioOptions: useMemo(
      () => [
        { id: ViewingOutcome.Positive, label: t('positive_feedback') },
        { id: ViewingOutcome.Neutral, label: t('neutral_feedback') },
        { id: ViewingOutcome.Negative, label: t('negative_feedback') },
      ],
      []
    ),
    weekDayOptions: useMemo(
      () => [
        { label: t('monday'), id: ShortWeekDay.Monday },
        { label: t('tuesday'), id: ShortWeekDay.Tuesday },
        { label: t('wednesday'), id: ShortWeekDay.Wednesday },
        { label: t('thursday'), id: ShortWeekDay.Thursday },
        { label: t('friday'), id: ShortWeekDay.Friday },
        { label: t('saturday'), id: ShortWeekDay.Saturday },
        { label: t('sunday'), id: ShortWeekDay.Sunday },
      ],
      []
    ),
  };

  return <OptionsContext.Provider value={result}>{children}</OptionsContext.Provider>;
};
