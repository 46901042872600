import { useEffect, useState } from 'react';

import { getQueries, setQueries } from '@shared/utils/common';

export type UseOverlaysRecord<T extends Item<string | boolean> = Item<string | boolean>> = T;

export const useOverlays = <T extends UseOverlaysRecord = UseOverlaysRecord>(initialOverlays: T) => {
  const [overlays, setOverlays] = useState<T>(initialOverlays);

  useEffect(() => {
    const newQueries = { ...getQueries() };

    for (const [key, overlay] of Object.entries(overlays)) {
      if (overlay) {
        newQueries[key] = overlay.toString();
      } else {
        delete newQueries[key];
      }
    }

    setQueries(newQueries);
  }, [overlays]);

  return { overlays, setOverlays };
};
