const baseUrls = {
  dev: {
    reactApp: 'https://app.agents-society-dev.com',
    proxy: 'https://agents-society-dev.com/',
  },
  preprod: {
    reactApp: 'https://app.agents-society-preprod.com',
    proxy: 'https://agents-society-preprod.com/',
  },
  test: {
    reactApp: 'https://app.agents-society-test.com',
    proxy: 'https://agents-society-test.com/',
  },
  demo: {
    reactApp: 'https://app.agents-society-demo.com',
    proxy: 'https://agents-society-demo.com/',
  },
  stage: {
    reactApp: 'https://app.agents-society-stage.com',
    proxy: 'https://agents-society-stage.com/',
  },
  gk: {
    reactApp: 'https://app.agents-society-dev-gk.com',
    proxy: 'https://agents-society-dev-gk.com/',
  },
  usyk: {
    reactApp: 'https://app.agents-society-dev-usyk.com',
    proxy: 'https://agents-society-dev-usyk.com/',
  },
  vidic: {
    reactApp: 'https://app.agents-society-dev-vidic.com',
    proxy: 'https://agents-society-dev-vidic.com/',
  },
  sandbox: {
    reactApp: 'https://app.agents-society-sandbox.com',
    proxy: 'https://agents-society-sandbox.com/',
  },
  production: {
    reactApp: 'https://app.agents-society.com',
    proxy: 'https://agents-society.com/',
  },
  local: {
    reactApp: 'http://app.agents-society.local:8080',
    proxy: 'http://agents-society.local/',
  },
};

export const getBaseUrl = (subDomain?: string) => {
  let baseUrl;

  switch (window.location.origin) {
    case baseUrls.dev.reactApp:
      baseUrl = baseUrls.dev.proxy;
      break;
    case baseUrls.test.reactApp:
      baseUrl = baseUrls.test.proxy;
      break;
    case baseUrls.preprod.reactApp:
      baseUrl = baseUrls.preprod.proxy;
      break;
    case baseUrls.demo.reactApp:
      baseUrl = baseUrls.demo.proxy;
      break;
    case baseUrls.stage.reactApp:
      baseUrl = baseUrls.stage.proxy;
      break;
    case baseUrls.gk.reactApp:
      baseUrl = baseUrls.gk.proxy;
      break;
    case baseUrls.usyk.reactApp:
      baseUrl = baseUrls.usyk.proxy;
      break;
    case baseUrls.vidic.reactApp:
      baseUrl = baseUrls.vidic.proxy;
      break;
    case baseUrls.sandbox.reactApp:
      baseUrl = baseUrls.sandbox.proxy;
      break;
    case baseUrls.production.reactApp:
      baseUrl = baseUrls.production.proxy;
      break;
    case baseUrls.local.reactApp:
      baseUrl = baseUrls.local.proxy;
      break;
    default:
      baseUrl = baseUrls.test.proxy;
  }

  if (subDomain) {
    if (baseUrl == baseUrls.local.proxy) {
      return baseUrls.local.proxy.replace('http://', `http://${subDomain}.`);
    }

    return baseUrl.replace('https://', `https://${subDomain}.`);
  }

  return baseUrl;
};
